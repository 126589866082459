@import-normalize;

body {
	overflow: hidden;
	margin: 0;
	font-size: 14px;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
		'Droid Sans', 'Helvetica Neue', sans-serif;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
}

body {
	--border-radius: 5px;
	--color-active: #373737;
	--color-background: #000;
	--color-surface: #1e1e1e;
	--color-text-secondary: #939393;
	--color-text: #fff;
	--gutter-small: 8px;
	--gutter: 16px;

	background: var(--color-background);
	color: var(--color-text);
}

html,
body,
#root {
	height: 100%;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

* {
	box-sizing: border-box;
	user-select: none;
}

button {
	margin: 0;
	padding: 0;
	border: none;
	background: none;
	color: inherit;
	font: inherit;
}

h1 {
	margin: var(--gutter-small) 0;
}
