.root {
	display: flex;
	flex-direction: column;
	height: 100%;
}

.title {
	text-align: center;
}

/* #region stage */

.stage {
	overflow: hidden;
}
.stage_inner {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100vw;
	height: 100vw;
}
.stage_bg,
.stage_fg {
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: 50% 50%;
}
.stage_fg {
	position: absolute;
	top: 0;
	left: 0;
	opacity: 0.5;
	touch-action: none;
}
.stage_empty {
	margin: var(--gutter);
	line-height: 1.4;
}
.stage_empty_button {
	text-decoration: underline;
}

/* #endregion stage */

@media (orientation: landscape) {
	.root {
		flex-direction: row;
	}
	.title {
		display: none;
	}
	.stage_inner {
		width: 100vh;
		height: 100vh;
	}
}

/* #region controls */

.controls {
	overflow-y: auto;
	flex: 1;
}
.controls_inner {
	display: grid;
	padding: var(--gutter-small);
	grid-template-columns: 100%;
	grid-auto-rows: min-content;
	gap: var(--gutter-small);
	grid-auto-rows: min-content;
}

.card {
	padding: var(--gutter);
	border-radius: var(--border-radius);
	background: var(--color-surface);
}
.card_title {
	display: flex;
	justify-content: space-between;
	margin-bottom: var(--gutter-small);
	color: var(--color-text-secondary);
}
.card_subtitle {
	color: var(--color-text);
}

.controls_imagesRow {
	display: grid;
	grid-template-columns: 1fr auto;
	gap: var(--gutter-small);
}
.imageInput + .imageInput {
	margin-left: var(--gutter);
}
.blink {
	width: 70px;
	height: 70px;
	border: 1px solid var(--color-text);
	border-radius: var(--border-radius);
}
.blink__active {
	background: var(--color-active);
}

.card__slider {
	grid-column: 1 / -1;
}
.slider {
	width: 100%;
}

.rotationInputGroup {
	display: flex;
}
.rotationInputGroup button:first-child {
	padding-right: var(--gutter-small);
}
.rotationInputGroup button:last-child {
	padding-left: var(--gutter-small);
}
.rotationInputGroup .slider {
	flex: 1;
}

.controls_aboutReset {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: var(--gutter-small);
}

.card__button {
	height: 70px;
}

/* #endregion controls */
