.root {
	width: 70px;
	height: 70px;
	border-radius: 2px;
	position: relative;
	align-items: center;
	display: inline-flex;
	background-size: cover;
	vertical-align: middle;
	border: 1px solid white;
	justify-content: center;
	background-position: 50% 50%;
	border: 1px solid var(--color-text);
	border-radius: var(--border-radius);
}

.root__selected {
	border-color: transparent;
}

.input {
	opacity: 0;
	position: absolute;
	pointer-events: none;
}

.title {
	top: 0;
	left: 0;
	margin: 4px;
	font-weight: bold;
	position: absolute;
}

.plus {
	font-size: 30px;
	font-weight: lighter;
}