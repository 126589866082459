.root {
	position: fixed;
	top: 0;
	left: 0;
	padding: var(--gutter);
	width: 100%;
	height: 100%;
}

.shield {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.5);
}

.body {
	position: relative;
	overflow-y: auto;
	padding: var(--gutter);
	width: 100%;
	height: 100%;
	border-radius: var(--border-radius);
	background: var(--color-surface);
}

.title {
	margin-top: 0;
}

.close {
	position: absolute;
	top: 0;
	right: 0;
	padding: var(--gutter);
	font-size: 26px;
}

.body {
	line-height: 1.4;
}
